<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-03-29 16:50:13
 * @LastEditTime: 2021-05-14 14:43:19
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="contact-us">
    <LogoTitle msg="联系我们"></LogoTitle>
    <div class="contact">
      <h3>思利及人公益基金会</h3>
      <p>电话: {{ detail.contentAuthor }}</p>
      <p>邮箱: {{ detail.contentSource }}</p>
      <p>地址：{{ detail.contentKeyword }}</p>
      <!-- <img class="map" src="../../assets/images/2@2x.png" alt="" /> -->
      <div class="map" v-html="detail.contentDetails"></div>
      <div class="wx">
        <img :src="detail.contentUrl" alt="二维码" />
        <p>扫码关注微信</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    detail () {
      return this.$store.state.concactUs
    }
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {

  },
}
</script>

<style scoped lang='scss'>
.contact {
  position: relative;
  font-size: 16px;
  h3 {
    font-size: 20px;
  }
  h3,
  > p {
    margin-bottom: 20px;
  }
  .map {
    margin-top: 40px;
    width: 100%;
  }
  .wx {
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    img {
      width: 110px;
      height: 110px;
      display: block;
    }
  }
}
</style>